.SubNav {
  display: none;
}

.buttonIcon {
  width: 10px;
  height: auto;
  /* top right bottom left */
  margin: 0 0.7em 0 0.7em;
  /* display: block; */
}

.arrowRight {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgb(68, 114, 196);
  cursor: pointer;
}

.arrowLeft {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgb(68, 114, 196);
  cursor: pointer;
}

.projTitle {
  font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  font-size: 2em;
  /* font-weight: bold; */
  /* top right bottom left */
  margin: 0 8px;
}

@media (max-width: 600px) {
  .SubNav {
    display: flex;
    align-items: center;
  }
}

/* 
Color Reference (for links)

blue: rgb(41, 148, 154)
orange: rgb(237, 125, 49) 

*/

@import url('https://fonts.googleapis.com/css?family=EB+Garamond|Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Tulpen+One&display=swap');

* {
  box-sizing: border-box;
  outline: none;
  font-family: 'Roboto', sans-serif;
  user-select: none;
  /* position: relative; */
  /* background-color: rgb(236, 242, 252); */
  /* z-index: 0; */
}

h3 {
  font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  font-size: 1.5em;
  font-weight: normal;
  margin: 10px 0;
}

.animateCircles {
  --animation-time: 2s;
  --blur-target: 50px;
  --scale-target: 1;
  --hue-target: 200deg;
}

@keyframes flicker {
  0% {
    opacity: 0.5;
    filter: blur(1px);
  }
  100% {
    opacity: 0;
    filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
    transform: scale(var(--scale-target, 1.5));
  }
}

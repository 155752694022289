.gotMeButton {
  box-sizing: border-box;
  height: 20px;
  width: 50px;
  border: 1px solid grey;
  border-radius: 2px;
  font-family: 'Tulpen One', cursive;
  font-size: 16px;
  text-align: center;
}

.gotMeButton:hover {
  color: rgb(237, 125, 49);
  border: 1px solid rgb(237, 125, 49);
}

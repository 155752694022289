.RotateWord {
  /* color: rgb(68, 114, 196); */
  /* animation: fadeIn 1s ease-in-out; */
  margin-right: 5px;
}

.Minor {
  color: rgb(87, 84, 136);
  font-style: italic;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

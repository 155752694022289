.titleBar {
  /* display: flex;
  flex-wrap: wrap; */
  min-width: 250px;
  /* width: 100%; */
  /* height: 80px; */
  /* background-color: white; */
  /* z-index: 20;
  position: absolute;
  top: 0;
  left: 0; */
}

.JClogo {
  width: 40px;
  height: auto;
  /* top right bottom left */
  margin: 0.6em 0.5em 0em 1.5em;
  /* padding: 1em 0 0 0; */
  /* z-index: 20; */
}

.logoText {
  min-width: 220px;
  white-space: nowrap;
  text-decoration: none;
  font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  /* margin-top: -5.2em; */
  position: relative;
  top: -6px;
  margin: 0 0 0 20px;
  /* top right bottom left */
  /* padding: 0 1em 0em 0.5em; */
  /* font-weight: bold; */
  font-size: 2.5em;
  outline: 0em;
  /* z-index: 20; */
}

.logoText:hover {
  /* background-color: rgb(190, 190, 190); */
  color: rgb(237, 125, 49);
}

.PicturesPage {
  display: flex;
  box-sizing: border-box;
}

.ThumbsOnly {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.ThumbsWImg {
  width: 180px;
  height: 80vh;
  overflow-y: auto;
  padding-right: 20px;
  /* scrollbar-width: none; */
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-grow: 0;
}

.ThumbsWImg::-webkit-scrollbar {
  /* width: 0px; */
  background: transparent;
}

.ThumbsWImg::-webkit-scrollbar-thumb {
  /* background: #a3a3a3; */
  background: rgb(146, 214, 218);
}

.ImgDisplay {
  height: 70vh;
  max-width: 600px;
  /* overflow-y: scroll; */
  padding: 0px 20px;
  /* display: block; */
}

@media (max-width: 600px) {
  .ImgDisplay {
    width: 100%;
    height: auto;
  }
  .ThumbsWImg {
    display: none;
  }
  .ThumbsOnly {
    /* width: 100%; */
    margin: auto;
  }
}

@media (min-width: 850px) {
  .ThumbsWImg {
    /* margin-left: 0%; */
    width: 300px;
  }
}

@media (min-width: 1000px) {
  .ThumbsWImg {
    /* margin-left: 0%; */
    width: 450px;
  }
}

.Dragging {
  cursor: grabbing;
  display: flex;
  z-index: 100;
}

.notDragging {
  display: flex;
  z-index: 100;
}

.ProjSummary {
  width: 100%;
  display: flex;
  flex-shrink: 0;
}

.linkItem {
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: black;
  padding: 0 0.7em;
  outline: 0em;
}

.activeLinkItem {
  color: rgb(41, 148, 154);
  order: -1;
  transition: order 0.5s linear;
}

.linkItem:hover {
  /* background-color: rgb(190, 190, 190); */
  color: rgb(237, 125, 49);
}

.List {
  margin-top: 15px;
  margin-left: -30px;
}

.faint {
  opacity: 0.4;
  display: none;
}

.backgroundDiv {
  /* background-color: red; */
  opacity: 1;
}

@media (max-width: 950px) {
  .List {
    display: none;
  }
}

.imgDiv {
  /* height: auto;
   max-height: 70vh; */
  height: 70vh;
  max-width: 95%;
  /* overflow: hidden; */
  display: block;
  float: left;
  /* padding: 20px; */
  box-sizing: border-box;
}

.picDisplay {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 89%;
  display: block;
  margin: auto;
}

.picDesc {
  display: block;
  float: left;
  font-size: 0.8em;
  margin-top: 10px;
  /* top right bottom left */
  margin: 10px 10px 0 10px;
  max-width: 850px;
}

.picTitle {
  /* font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  font-size: 1.5em;
  font-weight: bold; */
  /* top right bottom left */
  margin: 0 0.5em 10px 0.7em;
  white-space: nowrap;
  display: block;
}

@media (max-width: 600px) {
  .picTitle {
    display: none;
  }
}

li {
  margin-bottom: 8px;
}

.projectImg {
  width: 100%;
  margin-left: 25px;
  /* height: auto; */
  max-width: 500px;
  /* display: inline; */
  /* margin-left: auto;
   margin-right: auto; */
}

.inlineLink {
  color: rgb(237, 125, 49);
  text-decoration: none;
}

.pageBottom {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.sidenote {
  /* font-size: 0.8em; */
  color: grey;
}

@media (max-width: 600px) {
  .projTitle {
    display: none;
  }
}

.ProjectsPage {
  margin: auto;
  width: 98%;
  display: flex;
  box-sizing: border-box;
}

.ThumbsOnly {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.ThumbsWDetail {
  width: 270px;
  /* height: 80vh; */
  overflow-y: auto;
  padding-right: 20px;
  /* scrollbar-width: none; */
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-content: flex-start;
}

.ThumbsWDetail::-webkit-scrollbar {
  /* width: 0px; */
  background: transparent;
}

.ThumbsWDetail::-webkit-scrollbar-thumb {
  /* background: #a3a3a3; */
  background: rgb(146, 214, 218);
}

.displayProject {
  height: 80vh;
  max-width: 750px;
  /* overflow-y: scroll; */
  padding: 0px 20px;
  /* display: block; */
  visibility: visible;
  transform: translateX(0);
}

.displaySection {
  height: 80vh;
  max-width: 750px;
  /* overflow-y: scroll; */
  padding: 0px 20px;
  /* display: block; */
  display: none;
}

@media (max-width: 600px) {
  .DetailDisplay {
    width: 100%;
    height: auto;
  }
  .ThumbsWDetail {
    display: none;
  }
  .ThumbsOnly {
    /* width: 90%; */
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 950px) {
  .ThumbsWDetail {
    width: 140px;
  }
}

@media (min-width: 950px) {
  .displayProject {
    display: none;
  }
  .displaySection {
    display: flex;
  }
}

.titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Content {
  box-sizing: border-box;
  /* padding-left: 0.05%; */
  width: 99%;
  margin: auto;
  /* background-color: white; */
  /* position: absolute;
  top: 20%;
  z-index: 10; */
}

.skillContainer {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
}

.SkillClosed {
  border: 1px solid grey;
  box-shadow: 1px 1px 1px grey;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  cursor: pointer;
}

.SkillClosed:hover {
  border: 1px solid rgb(237, 125, 49);
  color: rgb(237, 125, 49);
}

.SkillOpen {
  border: 1px solid grey;
  box-shadow: 1px 1px 1px grey;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  cursor: pointer;
}

.SkillOpen:hover {
  border: 1px solid rgb(237, 125, 49);
  background: rgb(250, 247, 245);
  /* color: rgb(237, 125, 49); */
}

.exampleTitle {
  padding: 0;
}

.exampleContainer {
  font-size: 0.9em;
  /* margin: 1px auto 0 10px; */
  text-align: left;
  padding: 0;
}

.Examples {
  font-size: 0.9em;
  /* top right bottom left */
  margin: 0 0 0 -5px;
  text-align: left;
  padding: 0;
}

.navItems {
  /* width: 100%; */
  display: flex;
  /* flex-wrap: no-wrap; */
  /* padding: 0.3em; */
  /* background-color: white; */
  text-decoration: none;
  color: white;
  /* top right bottom left */
  padding: 0 8px;

  /* height: 80px; */
  /* position: absolute;
  top: 80px;
  left: 0px;
  z-index: 300; */
}

.linkItem {
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: black;
  padding: 0 0.7em;
  outline: 0em;
}

.activeLinkItem {
  color: rgb(41, 148, 154);
  /* order: -1; */
  transition: order 0.5s linear;
}

.linkItem:hover {
  /* background-color: rgb(190, 190, 190); */
  color: rgb(237, 125, 49);
}

@import url(https://fonts.googleapis.com/css?family=EB+Garamond|Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Tulpen+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:800&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 
Color Reference (for links)

blue: rgb(41, 148, 154)
orange: rgb(237, 125, 49) 

*/

* {
  box-sizing: border-box;
  outline: none;
  font-family: 'Roboto', sans-serif;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* position: relative; */
  /* background-color: rgb(236, 242, 252); */
  /* z-index: 0; */
}

h3 {
  font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  font-size: 1.5em;
  font-weight: normal;
  margin: 10px 0;
}

.animateCircles {
  --animation-time: 2s;
  --blur-target: 50px;
  --scale-target: 1;
  --hue-target: 200deg;
}

@-webkit-keyframes flicker {
  0% {
    opacity: 0.5;
    -webkit-filter: blur(1px);
            filter: blur(1px);
  }
  100% {
    opacity: 0;
    -webkit-filter: hue-rotate(270deg) blur(50px);
            filter: hue-rotate(270deg) blur(50px);
    -webkit-filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
            filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transform: scale(var(--scale-target, 1.5));
            transform: scale(var(--scale-target, 1.5));
  }
}

@keyframes flicker {
  0% {
    opacity: 0.5;
    -webkit-filter: blur(1px);
            filter: blur(1px);
  }
  100% {
    opacity: 0;
    -webkit-filter: hue-rotate(270deg) blur(50px);
            filter: hue-rotate(270deg) blur(50px);
    -webkit-filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
            filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transform: scale(var(--scale-target, 1.5));
            transform: scale(var(--scale-target, 1.5));
  }
}

.Thumbnail_projThumbnailImg__1ryNT {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  /* width: 200px;
  background-color: red; */
  display: flex;
}

.Thumbnail_projThumbnailImgActive__1nbgF {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: flex;
  order: -1;
}

.Thumbnail_projThumbnailImg__1ryNT:hover {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: flex;
}

.Thumbnail_backgroundDiv__3v59r {
  /* background-color: red; */
  display: flex;
  /* align-content: flex-start; */
  height: 120px;
}

.Thumbnail_picThumbnailImg__sDDWJ {
  width: 100px;
  height: 100px;
  margin: 10px;
  object-fit: cover;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  filter: contrast(200%) saturate(200%) hue-rotate(180deg);
  -webkit-filter: contrast(200%) saturate(200%) hue-rotate(180deg);
  display: flex;
}

.Thumbnail_picThumbnailImgActive__LZ-QN,
.Thumbnail_picThumbnailImg__sDDWJ:hover {
  width: 100px;
  height: 100px;
  margin: 10px;
  object-fit: cover;
  border: 2px solid black;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  /* 
  filter: contrast(200%) saturate(200%) hue-rotate(180deg);
  -webkit-filter: contrast(200%) saturate(200%) hue-rotate(180deg); */
  display: flex;
}

.Thumbnail_Faint__21-PM {
  opacity: 0.3;
}

.Picture_imgDiv__q8gL2 {
  /* height: auto;
   max-height: 70vh; */
  height: 70vh;
  max-width: 95%;
  /* overflow: hidden; */
  display: block;
  float: left;
  /* padding: 20px; */
  box-sizing: border-box;
}

.Picture_picDisplay__1gTiL {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 89%;
  display: block;
  margin: auto;
}

.Picture_picDesc__fGUU5 {
  display: block;
  float: left;
  font-size: 0.8em;
  margin-top: 10px;
  /* top right bottom left */
  margin: 10px 10px 0 10px;
  max-width: 850px;
}

.Picture_picTitle__3S-pu {
  /* font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  font-size: 1.5em;
  font-weight: bold; */
  /* top right bottom left */
  margin: 0 0.5em 10px 0.7em;
  white-space: nowrap;
  display: block;
}

@media (max-width: 600px) {
  .Picture_picTitle__3S-pu {
    display: none;
  }
}

.SubNav_SubNav__T3LZe {
  display: none;
}

.SubNav_buttonIcon__35B2P {
  width: 10px;
  height: auto;
  /* top right bottom left */
  margin: 0 0.7em 0 0.7em;
  /* display: block; */
}

.SubNav_arrowRight__FZN8k {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgb(68, 114, 196);
  cursor: pointer;
}

.SubNav_arrowLeft__yyJm2 {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgb(68, 114, 196);
  cursor: pointer;
}

.SubNav_projTitle__3N7Hk {
  font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  font-size: 2em;
  /* font-weight: bold; */
  /* top right bottom left */
  margin: 0 8px;
}

@media (max-width: 600px) {
  .SubNav_SubNav__T3LZe {
    display: flex;
    align-items: center;
  }
}

.Pictures_PicturesPage__1KSov {
  display: flex;
  box-sizing: border-box;
}

.Pictures_ThumbsOnly__3In2Y {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.Pictures_ThumbsWImg__3aXMk {
  width: 180px;
  height: 80vh;
  overflow-y: auto;
  padding-right: 20px;
  /* scrollbar-width: none; */
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-grow: 0;
}

.Pictures_ThumbsWImg__3aXMk::-webkit-scrollbar {
  /* width: 0px; */
  background: transparent;
}

.Pictures_ThumbsWImg__3aXMk::-webkit-scrollbar-thumb {
  /* background: #a3a3a3; */
  background: rgb(146, 214, 218);
}

.Pictures_ImgDisplay__jwGGk {
  height: 70vh;
  max-width: 600px;
  /* overflow-y: scroll; */
  padding: 0px 20px;
  /* display: block; */
}

@media (max-width: 600px) {
  .Pictures_ImgDisplay__jwGGk {
    width: 100%;
    height: auto;
  }
  .Pictures_ThumbsWImg__3aXMk {
    display: none;
  }
  .Pictures_ThumbsOnly__3In2Y {
    /* width: 100%; */
    margin: auto;
  }
}

@media (min-width: 850px) {
  .Pictures_ThumbsWImg__3aXMk {
    /* margin-left: 0%; */
    width: 300px;
  }
}

@media (min-width: 1000px) {
  .Pictures_ThumbsWImg__3aXMk {
    /* margin-left: 0%; */
    width: 450px;
  }
}

li {
  margin-bottom: 8px;
}

.Project_projectImg__mgd6W {
  width: 100%;
  margin-left: 25px;
  /* height: auto; */
  max-width: 500px;
  /* display: inline; */
  /* margin-left: auto;
   margin-right: auto; */
}

.Project_inlineLink__1X69W {
  color: rgb(237, 125, 49);
  text-decoration: none;
}

.Project_pageBottom__1tB2b {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.Project_sidenote__2EdM- {
  /* font-size: 0.8em; */
  color: grey;
}

@media (max-width: 600px) {
  .Project_projTitle__23lX_ {
    display: none;
  }
}

.Projects_ProjectsPage__250NU {
  margin: auto;
  width: 98%;
  display: flex;
  box-sizing: border-box;
}

.Projects_ThumbsOnly__2QOw8 {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.Projects_ThumbsWDetail__FReuV {
  width: 270px;
  /* height: 80vh; */
  overflow-y: auto;
  padding-right: 20px;
  /* scrollbar-width: none; */
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-content: flex-start;
}

.Projects_ThumbsWDetail__FReuV::-webkit-scrollbar {
  /* width: 0px; */
  background: transparent;
}

.Projects_ThumbsWDetail__FReuV::-webkit-scrollbar-thumb {
  /* background: #a3a3a3; */
  background: rgb(146, 214, 218);
}

.Projects_displayProject__JBbnf {
  height: 80vh;
  max-width: 750px;
  /* overflow-y: scroll; */
  padding: 0px 20px;
  /* display: block; */
  visibility: visible;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.Projects_displaySection__2tlXq {
  height: 80vh;
  max-width: 750px;
  /* overflow-y: scroll; */
  padding: 0px 20px;
  /* display: block; */
  display: none;
}

@media (max-width: 600px) {
  .Projects_DetailDisplay__3G4hc {
    width: 100%;
    height: auto;
  }
  .Projects_ThumbsWDetail__FReuV {
    display: none;
  }
  .Projects_ThumbsOnly__2QOw8 {
    /* width: 90%; */
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 950px) {
  .Projects_ThumbsWDetail__FReuV {
    width: 140px;
  }
}

@media (min-width: 950px) {
  .Projects_displayProject__JBbnf {
    display: none;
  }
  .Projects_displaySection__2tlXq {
    display: flex;
  }
}

.ProjSummary_ProjSummary__1x7Hm {
  width: 100%;
  display: flex;
  flex-shrink: 0;
}

.ProjSummary_linkItem__PvZvd {
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: black;
  padding: 0 0.7em;
  outline: 0em;
}

.ProjSummary_activeLinkItem__v_Efn {
  color: rgb(41, 148, 154);
  order: -1;
  -webkit-transition: order 0.5s linear;
  transition: order 0.5s linear;
}

.ProjSummary_linkItem__PvZvd:hover {
  /* background-color: rgb(190, 190, 190); */
  color: rgb(237, 125, 49);
}

.ProjSummary_List__1__Z4 {
  margin-top: 15px;
  margin-left: -30px;
}

.ProjSummary_faint__3AsXd {
  opacity: 0.4;
  display: none;
}

.ProjSummary_backgroundDiv__3-ex2 {
  /* background-color: red; */
  opacity: 1;
}

@media (max-width: 950px) {
  .ProjSummary_List__1__Z4 {
    display: none;
  }
}

.Title_Title__1MuQQ {
  font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  font-size: 2em;
  /* font-weight: bold; */
  /* top right bottom left */
  margin: 0 0 12px 0;
}

.ProjViewer_inlineLink__yJrq1 {
  font-family: 'Open Sans', sans-serif;
  color: black;
  text-decoration: none;
  font-weight: normal;
  font-size: 0.6em;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid black;
  border-radius: 15%;
  display: inline-block;
}

.ProjViewer_Active__3Ltdz {
  color: rgb(41, 148, 154);
  background-color: rgb(227, 249, 250);
  border: 1px solid rgb(41, 148, 154);
}

.ProjViewer_inlineLink__yJrq1:hover {
  color: rgb(237, 125, 49);
  background: rgb(250, 247, 245);
  border: 1px solid rgb(237, 125, 49);
}

.ProjViewer_processDiv__1k3zm {
  max-height: 50vh;
}

.About_aboutPage__20oCd {
  padding: 0 10px;
}

.About_inlineLink__1Q3bz {
  color: black;
  text-decoration: none;
}

.About_inlineLink__1Q3bz:hover {
  color: rgb(237, 125, 49);
}

.About_flexContainer__5oi0O {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  justify-content: center;
}

.About_title__2atpf {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  justify-content: center;
  margin: 30px 0 5px 0;
}

.About_skillsFlexContainer__KRgkr {
  display: flex;
  flex-wrap: wrap;
  max-width: 30%;
}

.About_projectImg__2vgNK {
  width: 100%;
  max-width: 500px;
  /* display: inline; */
  /* margin-left: auto;
    margin-right: auto; */
}

@media (max-width: 350px) {
  .About_skillsFlexContainer__KRgkr {
    max-width: 100%;
  }
}

@media (min-width: 350px) and (max-width: 600px) {
  .About_skillsFlexContainer__KRgkr {
    max-width: 50%;
  }
}

.Skill_skillContainer__1kwwb {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
}

.Skill_SkillClosed__11sFv {
  border: 1px solid grey;
  box-shadow: 1px 1px 1px grey;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  cursor: pointer;
}

.Skill_SkillClosed__11sFv:hover {
  border: 1px solid rgb(237, 125, 49);
  color: rgb(237, 125, 49);
}

.Skill_SkillOpen__1yQAH {
  border: 1px solid grey;
  box-shadow: 1px 1px 1px grey;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  cursor: pointer;
}

.Skill_SkillOpen__1yQAH:hover {
  border: 1px solid rgb(237, 125, 49);
  background: rgb(250, 247, 245);
  /* color: rgb(237, 125, 49); */
}

.Skill_exampleTitle__1Qhny {
  padding: 0;
}

.Skill_exampleContainer__2KWbv {
  font-size: 0.9em;
  /* margin: 1px auto 0 10px; */
  text-align: left;
  padding: 0;
}

.Skill_Examples__3USbT {
  font-size: 0.9em;
  /* top right bottom left */
  margin: 0 0 0 -5px;
  text-align: left;
  padding: 0;
}

.Homepage_mainPage__34CGl {
  width: 99vw;
  height: calc(100vh - 80px);
  --homeBkgndCol: #ffffff;
  --flexWidth: 800px;
  --largeFontSize: 13vh;
  --largeLineHeight: 13vh;
  /* background-color: var(--homeBkgndCol); */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Homepage_Thumbnail__2RCo5 {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  display: flex;
}

/* --------------------------------------- */

.Homepage_spacer__2d3Vq {
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}

.Homepage_flexRow__1sP5g {
  display: flex;
  flex-direction: row;
  /* T R B L */
  margin: 10px 10px 0px 0px;
  position: relative;
}

.Homepage_flexCol__1L6nt {
  display: flex;
  flex-direction: column;
  position: relative;
  background: transparent;
  z-index: 0;
}

.Homepage_setLayer__1TQqf {
  width: auto;
}

.Homepage_contHead__RsOZn {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--largeFontSize);
  line-height: var(--largeLineHeight);
  position: relative;
  width: var(--flexWidth);
}

.Homepage_Head__1xZPn {
  margin-right: 17px;
  background-color: var(--homeBkgndCol);
  display: flex;
  position: relative;

  /* color: green; */
  font-family: 'EB Garamond', serif;
}

.Homepage_contWord__3A52W {
  font-size: var(--largeFontSize);
  line-height: var(--largeLineHeight);
  position: relative;
  display: inline;
  width: var(--flexWidth);
}

.Homepage_Word__mnydz {
  display: inline-block;
  width: auto;
  color: rgb(68, 114, 196);
  background-color: var(--homeBkgndCol);
  font-family: 'EB Garamond', serif;
}

.Homepage_contBullet__1iSun {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 180px;
  /* margin-left: 10px; */
  position: relative;
}

.Homepage_Bullet__RPl0m {
  /* color: green; */
  display: flex;
  flex-direction: column;
  background-color: var(--homeBkgndCol);

  position: relative;
  font-size: 16px;
  line-height: 23px;
}

.Homepage_Bullet1__UoFl5 {
  display: flex;
  flex-direction: column;
  background-color: var(--homeBkgndCol);
  position: relative;
  font-size: 16px;
  height: 23px;
  width: 50px;
}
.Homepage_contContact__3ph39 {
  display: flex;
  flex-direction: column;
  width: calc(var(--flexWidth) - 345px);
  background-color: var(--homeBkgndCol);
  position: relative;
}

.Homepage_Contact__39MWv {
  display: flex;
  background-color: var(--homeBkgndCol);
  position: relative;
  font-size: 16px;
  line-height: 23px;
}

.Homepage_Contact1__3AekU {
  display: flex;
  background-color: var(--homeBkgndCol);
  position: relative;
  font-size: 16px;
  height: 23px;
  width: 70px;
}

.Homepage_playButton__2SjNW {
  z-index: 40;
  opacity: 1;
  position: absolute;
  bottom: 3em;
  right: 125px;
  border: 1px solid grey;
  padding: 2px;
  border-radius: 2px;
  font-family: 'Tulpen One', cursive;
  font-size: 1.2em;
  width: 50px;
  text-align: center;
}

.Homepage_gotMeButton__riDAk:hover,
.Homepage_playButton__2SjNW:hover {
  color: rgb(237, 125, 49);
  border: 1px solid rgb(237, 125, 49);
}

.Homepage_gotMeButton__riDAk {
  z-index: 30;
  height: 23px;
  width: 50px;
  position: absolute;
  border: 1px solid grey;
  padding: 1px;
  border-radius: 2px;
  font-family: 'Tulpen One', cursive;
  font-size: 16px;
  text-align: center;
  z-index: 0;
  opacity: 0.99;
}

/* ---------------------- */
.Homepage_Circles__1VBm1 {
  --animation-time: 2s;
  --blur-target: 50px;
  --scale-target: 1;
  --hue-target: 200deg;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 100px;
  left: 200px;
  background-color: rgb(13, 150, 150);
  border-radius: 50%;
  border-color: rgb(13, 150, 150);
  border: 10px;
  z-index: 3;
  -webkit-animation: Homepage_flicker__3QM68 1s ease alternate infinite;
          animation: Homepage_flicker__3QM68 1s ease alternate infinite;
}

@-webkit-keyframes Homepage_flicker__3QM68 {
  0% {
    opacity: 0.5;
    -webkit-filter: blur(1px);
            filter: blur(1px);
  }
  100% {
    opacity: 0;
    -webkit-filter: hue-rotate(270deg) blur(50px);
            filter: hue-rotate(270deg) blur(50px);
    -webkit-filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
            filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transform: scale(var(--scale-target, 1.5));
            transform: scale(var(--scale-target, 1.5));
  }
}

@keyframes Homepage_flicker__3QM68 {
  0% {
    opacity: 0.5;
    -webkit-filter: blur(1px);
            filter: blur(1px);
  }
  100% {
    opacity: 0;
    -webkit-filter: hue-rotate(270deg) blur(50px);
            filter: hue-rotate(270deg) blur(50px);
    -webkit-filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
            filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transform: scale(var(--scale-target, 1.5));
            transform: scale(var(--scale-target, 1.5));
  }
}

@media (max-width: 960px) {
  .Homepage_mainPage__34CGl {
    flex-direction: column;
    background-color: red;
    --flexWidth: 520px;
    --largeLineHeight: 16.5vh;
  }
}

@media (max-width: 650px) {
  .Homepage_mainPage__34CGl {
    flex-direction: column;
    background-color: green;
    --flexWidth: 520px;
    --largeFontSize: 10vh;
    align-items: flex-start;
    padding-left: 30px;
  }
  .Homepage_flexRow__1sP5g {
    flex-direction: column;
  }
}

.Container_Dragging__145Rm {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  display: flex;
  z-index: 100;
}

.Container_notDragging__-Le9b {
  display: flex;
  z-index: 100;
}

.draggable_gotMeButton__2nZkc {
  box-sizing: border-box;
  height: 20px;
  width: 50px;
  border: 1px solid grey;
  border-radius: 2px;
  font-family: 'Tulpen One', cursive;
  font-size: 16px;
  text-align: center;
}

.draggable_gotMeButton__2nZkc:hover {
  color: rgb(237, 125, 49);
  border: 1px solid rgb(237, 125, 49);
}

.Shake_Shake__1VScR {
}

/* ----------------------------- */
.Shake_swingTL__1Zq80 {
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-animation: Shake_swingTL__1Zq80 4s infinite;
          animation: Shake_swingTL__1Zq80 4s infinite;
  z-index: 10;
}

@-webkit-keyframes Shake_swingTL__1Zq80 {
  5% {
    -webkit-transform: rotate3d(0, 0, 1, 6deg);
    transform: rotate3d(0, 0, 1, 6deg);
  }

  7% {
    -webkit-transform: rotate3d(0, 0, 1, -0deg);
    transform: rotate3d(0, 0, 1, -0deg);
  }

  10% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  15% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes Shake_swingTL__1Zq80 {
  5% {
    -webkit-transform: rotate3d(0, 0, 1, 6deg);
    transform: rotate3d(0, 0, 1, 6deg);
  }

  7% {
    -webkit-transform: rotate3d(0, 0, 1, -0deg);
    transform: rotate3d(0, 0, 1, -0deg);
  }

  10% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  15% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

/* -------------------------------------------------------- */

.Shake_swingTR__2eQMh {
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-animation: Shake_swingTR__2eQMh 4s infinite;
          animation: Shake_swingTR__2eQMh 4s infinite;
  z-index: 10;
}

@-webkit-keyframes Shake_swingTR__2eQMh {
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -6deg);
    transform: rotate3d(0, 0, 1, -6deg);
  }

  83% {
    -webkit-transform: rotate3d(0, 0, 1, -1deg);
    transform: rotate3d(0, 0, 1, -1deg);
  }

  86% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  89% {
    -webkit-transform: rotate3d(0, 0, 1, -1deg);
    transform: rotate3d(0, 0, 1, -1deg);
  }
  92% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes Shake_swingTR__2eQMh {
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -6deg);
    transform: rotate3d(0, 0, 1, -6deg);
  }

  83% {
    -webkit-transform: rotate3d(0, 0, 1, -1deg);
    transform: rotate3d(0, 0, 1, -1deg);
  }

  86% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  89% {
    -webkit-transform: rotate3d(0, 0, 1, -1deg);
    transform: rotate3d(0, 0, 1, -1deg);
  }
  92% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

/* ------------------------------------- */

.Shake_swingTC__2LQQW {
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-animation: Shake_swingTC__2LQQW 4s infinite;
          animation: Shake_swingTC__2LQQW 4s infinite;
  z-index: 10;
}

@-webkit-keyframes Shake_swingTC__2LQQW {
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  52% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  54% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  56% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  58% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes Shake_swingTC__2LQQW {
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  52% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  54% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  56% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  58% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.RotateWord_RotateWord__2MWea {
  /* color: rgb(68, 114, 196); */
  /* animation: fadeIn 1s ease-in-out; */
  margin-right: 5px;
}

.RotateWord_Minor__z2_j8 {
  color: rgb(87, 84, 136);
  font-style: italic;
}

@-webkit-keyframes RotateWord_fadeIn__3zoPu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes RotateWord_fadeIn__3zoPu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.RotateWord_fadeIn__3zoPu {
  -webkit-animation-name: RotateWord_fadeIn__3zoPu;
  animation-name: RotateWord_fadeIn__3zoPu;
}

/* skeleton.css contains css specific to the skeleton */

#base {
  background: url(/portfolio2/static/media/skeletonsprites3.afea5cd4.png) 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  -webkit-animation: skeletonWalk 1.25s steps(13) 20, moveRight 25s linear, skeletonIdle 2s steps(11) 25s 2,
    skeletonAttack 2s steps(18) 29s 2, skeletonDie 2s steps(14) 33s 1;
          animation: skeletonWalk 1.25s steps(13) 20, moveRight 25s linear, skeletonIdle 2s steps(11) 25s 2,
    skeletonAttack 2s steps(18) 29s 2, skeletonDie 2s steps(14) 33s 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 300;
}

#walkOff {
  background: url(/portfolio2/static/media/skeletonsprites3.afea5cd4.png) 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  -webkit-animation: skeletonWalk 1.25s steps(13) 40, moveOff 50s linear;
          animation: skeletonWalk 1.25s steps(13) 40, moveOff 50s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 300;
}

#walk {
  background: url(/portfolio2/static/media/skeletonsprites3.afea5cd4.png) 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  -webkit-animation: skeletonWalk 1.25s steps(13) 10, walkIn 12.5s linear, skeletonIdle 2s steps(11) 12.5s 2,
    skeletonAttack 2s steps(18) 14s 2, skeletonIdle 2s steps(11) 18s infinite;
          animation: skeletonWalk 1.25s steps(13) 10, walkIn 12.5s linear, skeletonIdle 2s steps(11) 12.5s 2,
    skeletonAttack 2s steps(18) 14s 2, skeletonIdle 2s steps(11) 18s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 300;
}

#die {
  background: url(/portfolio2/static/media/skeletonsprites3.afea5cd4.png) 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  -webkit-animation: walkIn 0.1s linear, skeletonDie 3s steps(14) 1;
          animation: walkIn 0.1s linear, skeletonDie 3s steps(14) 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 300;
}

@-webkit-keyframes skeletonIdle {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1276px 0;
  }
}

@keyframes skeletonIdle {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1276px 0;
  }
}

@-webkit-keyframes skeletonWalk {
  0% {
    background-position: 0 -100px;
  }
  100% {
    background-position: -1508px -100px;
  }
}

@keyframes skeletonWalk {
  0% {
    background-position: 0 -100px;
  }
  100% {
    background-position: -1508px -100px;
  }
}

@-webkit-keyframes skeletonDie {
  0% {
    background-position: 0 -200px;
  }
  100% {
    background-position: -1624px -200px;
  }
}

@keyframes skeletonDie {
  0% {
    background-position: 0 -200px;
  }
  100% {
    background-position: -1624px -200px;
  }
}

@-webkit-keyframes skeletonAttack {
  0% {
    background-position: 0 -300px;
  }
  100% {
    background-position: -2092px -300px;
  }
}

@keyframes skeletonAttack {
  0% {
    background-position: 0 -300px;
  }
  100% {
    background-position: -2092px -300px;
  }
}

@-webkit-keyframes moveRight {
  0%,
  1% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  100% {
    -webkit-transform: translateX(70vw);
            transform: translateX(70vw);
  }
}

@keyframes moveRight {
  0%,
  1% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  100% {
    -webkit-transform: translateX(70vw);
            transform: translateX(70vw);
  }
}
@-webkit-keyframes moveOff {
  0%,
  1% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  100% {
    -webkit-transform: translateX(110vw);
            transform: translateX(110vw);
  }
}
@keyframes moveOff {
  0%,
  1% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  100% {
    -webkit-transform: translateX(110vw);
            transform: translateX(110vw);
  }
}

@-webkit-keyframes walkIn {
  0%,
  1% {
    -webkit-transform: translateX(-5vw);
            transform: translateX(-5vw);
  }
  100% {
    -webkit-transform: translateX(70vw);
            transform: translateX(70vw);
  }
}

@keyframes walkIn {
  0%,
  1% {
    -webkit-transform: translateX(-5vw);
            transform: translateX(-5vw);
  }
  100% {
    -webkit-transform: translateX(70vw);
            transform: translateX(70vw);
  }
}

/* .modal {
  z-index: 300;
  font-family: 'Tulpen One', cursive;
  position: absolute;
  bottom: 3.5em;
  animation: walkIn 2.5s linear forwards;
} */

.Modal_content__2xKU7 {
  font-family: 'Tulpen One', cursive;
  font-size: 1.5em;
}

.Modal_open__wuDQI {
  z-index: 300;
  font-family: 'Tulpen One', cursive;
  position: absolute;
  right: 12px;
  bottom: 5.5em;
  width: 240px;
  height: auto;
  border: 1px solid grey;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
}

.Modal_closed__1F3id {
  z-index: 300;
  font-family: 'Tulpen One', cursive;
  position: absolute;
  right: 12px;
  bottom: 2em;
  /* animation: walkIn 2.5s linear forwards; */
  color: black;
  font-size: 1.2em;
  width: 150px;
}

.Modal_closed__1F3id:hover {
  color: rgb(237, 125, 49);
}

.Modal_open__wuDQI:hover {
  border: 1px solid rgb(237, 125, 49);
  color: rgb(237, 125, 49);
}

@-webkit-keyframes Modal_walkIn__7e0aL {
  0%,
  1% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  100% {
    -webkit-transform: translateX(5vw);
            transform: translateX(5vw);
  }
}

@keyframes Modal_walkIn__7e0aL {
  0%,
  1% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
  }
  100% {
    -webkit-transform: translateX(5vw);
            transform: translateX(5vw);
  }
}

/* ---------------------- */

.Modal_cross__mZN-z {
  background: rgb(237, 125, 49);
  height: 21px;
  position: relative;
  width: 1px;
}
.Modal_cross__mZN-z:after {
  background: rgb(237, 125, 49);
  content: '';
  height: 1px;
  left: -10px;
  position: absolute;
  top: 10px;
  width: 21px;
}

.Modal_cross__mZN-z:after:hover,
.Modal_cross__mZN-z:hover {
  background: rgb(237, 125, 49);
}

.Modal_crossPosition__222t1 {
  /* position: relative; */
  -webkit-transform: translate(197px, -44px) rotate(45deg);
          transform: translate(197px, -44px) rotate(45deg);
}

.Logo_titleBar__1stBc {
  /* display: flex;
  flex-wrap: wrap; */
  min-width: 250px;
  /* width: 100%; */
  /* height: 80px; */
  /* background-color: white; */
  /* z-index: 20;
  position: absolute;
  top: 0;
  left: 0; */
}

.Logo_JClogo__2oer9 {
  width: 40px;
  height: auto;
  /* top right bottom left */
  margin: 0.6em 0.5em 0em 1.5em;
  /* padding: 1em 0 0 0; */
  /* z-index: 20; */
}

.Logo_logoText__3uQt9 {
  min-width: 220px;
  white-space: nowrap;
  text-decoration: none;
  font-family: 'EB Garamond', serif;
  color: rgb(68, 114, 196);
  /* margin-top: -5.2em; */
  position: relative;
  top: -6px;
  margin: 0 0 0 20px;
  /* top right bottom left */
  /* padding: 0 1em 0em 0.5em; */
  /* font-weight: bold; */
  font-size: 2.5em;
  outline: 0em;
  /* z-index: 20; */
}

.Logo_logoText__3uQt9:hover {
  /* background-color: rgb(190, 190, 190); */
  color: rgb(237, 125, 49);
}

.NavItems_navItems__2dBTp {
  /* width: 100%; */
  display: flex;
  /* flex-wrap: no-wrap; */
  /* padding: 0.3em; */
  /* background-color: white; */
  text-decoration: none;
  color: white;
  /* top right bottom left */
  padding: 0 8px;

  /* height: 80px; */
  /* position: absolute;
  top: 80px;
  left: 0px;
  z-index: 300; */
}

.NavItems_linkItem__3ct8z {
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: black;
  padding: 0 0.7em;
  outline: 0em;
}

.NavItems_activeLinkItem__2yzj4 {
  color: rgb(41, 148, 154);
  /* order: -1; */
  -webkit-transition: order 0.5s linear;
  transition: order 0.5s linear;
}

.NavItems_linkItem__3ct8z:hover {
  /* background-color: rgb(190, 190, 190); */
  color: rgb(237, 125, 49);
}

.Layout_titleBar__1zhDs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Layout_Content__2EQDV {
  box-sizing: border-box;
  /* padding-left: 0.05%; */
  width: 99%;
  margin: auto;
  /* background-color: white; */
  /* position: absolute;
  top: 20%;
  z-index: 10; */
}


.inlineLink {
  font-family: 'Open Sans', sans-serif;
  color: black;
  text-decoration: none;
  font-weight: normal;
  font-size: 0.6em;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid black;
  border-radius: 15%;
  display: inline-block;
}

.Active {
  color: rgb(41, 148, 154);
  background-color: rgb(227, 249, 250);
  border: 1px solid rgb(41, 148, 154);
}

.inlineLink:hover {
  color: rgb(237, 125, 49);
  background: rgb(250, 247, 245);
  border: 1px solid rgb(237, 125, 49);
}

.processDiv {
  max-height: 50vh;
}

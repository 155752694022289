.projThumbnailImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  /* width: 200px;
  background-color: red; */
  display: flex;
}

.projThumbnailImgActive {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: flex;
  order: -1;
}

.projThumbnailImg:hover {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: flex;
}

.backgroundDiv {
  /* background-color: red; */
  display: flex;
  /* align-content: flex-start; */
  height: 120px;
}

.picThumbnailImg {
  width: 100px;
  height: 100px;
  margin: 10px;
  object-fit: cover;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  filter: contrast(200%) saturate(200%) hue-rotate(180deg);
  -webkit-filter: contrast(200%) saturate(200%) hue-rotate(180deg);
  display: flex;
}

.picThumbnailImgActive,
.picThumbnailImg:hover {
  width: 100px;
  height: 100px;
  margin: 10px;
  object-fit: cover;
  border: 2px solid black;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  /* 
  filter: contrast(200%) saturate(200%) hue-rotate(180deg);
  -webkit-filter: contrast(200%) saturate(200%) hue-rotate(180deg); */
  display: flex;
}

.Faint {
  opacity: 0.3;
}

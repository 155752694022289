.aboutPage {
  padding: 0 10px;
}

.inlineLink {
  color: black;
  text-decoration: none;
}

.inlineLink:hover {
  color: rgb(237, 125, 49);
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  justify-content: center;
}

.title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  justify-content: center;
  margin: 30px 0 5px 0;
}

.skillsFlexContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 30%;
}

.projectImg {
  width: 100%;
  max-width: 500px;
  /* display: inline; */
  /* margin-left: auto;
    margin-right: auto; */
}

@media (max-width: 350px) {
  .skillsFlexContainer {
    max-width: 100%;
  }
}

@media (min-width: 350px) and (max-width: 600px) {
  .skillsFlexContainer {
    max-width: 50%;
  }
}

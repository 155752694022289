.Shake {
}

/* ----------------------------- */
.swingTL {
  transform-origin: top left;
  animation: swingTL 4s infinite;
  z-index: 10;
}

@keyframes swingTL {
  5% {
    -webkit-transform: rotate3d(0, 0, 1, 6deg);
    transform: rotate3d(0, 0, 1, 6deg);
  }

  7% {
    -webkit-transform: rotate3d(0, 0, 1, -0deg);
    transform: rotate3d(0, 0, 1, -0deg);
  }

  10% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  15% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

/* -------------------------------------------------------- */

.swingTR {
  transform-origin: top right;
  animation: swingTR 4s infinite;
  z-index: 10;
}

@keyframes swingTR {
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -6deg);
    transform: rotate3d(0, 0, 1, -6deg);
  }

  83% {
    -webkit-transform: rotate3d(0, 0, 1, -1deg);
    transform: rotate3d(0, 0, 1, -1deg);
  }

  86% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  89% {
    -webkit-transform: rotate3d(0, 0, 1, -1deg);
    transform: rotate3d(0, 0, 1, -1deg);
  }
  92% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

/* ------------------------------------- */

.swingTC {
  transform-origin: top center;
  animation: swingTC 4s infinite;
  z-index: 10;
}

@keyframes swingTC {
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  52% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  54% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  56% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  58% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

/* skeleton.css contains css specific to the skeleton */

#base {
  background: url('../../../assets/skeletonsprites3.png') 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  animation: skeletonWalk 1.25s steps(13) 20, moveRight 25s linear, skeletonIdle 2s steps(11) 25s 2,
    skeletonAttack 2s steps(18) 29s 2, skeletonDie 2s steps(14) 33s 1;
  animation-fill-mode: forwards;
  z-index: 300;
}

#walkOff {
  background: url('../../../assets/skeletonsprites3.png') 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  animation: skeletonWalk 1.25s steps(13) 40, moveOff 50s linear;
  animation-fill-mode: forwards;
  z-index: 300;
}

#walk {
  background: url('../../../assets/skeletonsprites3.png') 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  animation: skeletonWalk 1.25s steps(13) 10, walkIn 12.5s linear, skeletonIdle 2s steps(11) 12.5s 2,
    skeletonAttack 2s steps(18) 14s 2, skeletonIdle 2s steps(11) 18s infinite;
  animation-fill-mode: forwards;
  z-index: 300;
}

#die {
  background: url('../../../assets/skeletonsprites3.png') 0 0 no-repeat;

  height: 100px;
  width: 116px;
  position: absolute;
  bottom: 3em;
  animation: walkIn 0.1s linear, skeletonDie 3s steps(14) 1;
  animation-fill-mode: forwards;
  z-index: 300;
}

@keyframes skeletonIdle {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1276px 0;
  }
}

@keyframes skeletonWalk {
  0% {
    background-position: 0 -100px;
  }
  100% {
    background-position: -1508px -100px;
  }
}

@keyframes skeletonDie {
  0% {
    background-position: 0 -200px;
  }
  100% {
    background-position: -1624px -200px;
  }
}

@keyframes skeletonAttack {
  0% {
    background-position: 0 -300px;
  }
  100% {
    background-position: -2092px -300px;
  }
}

@keyframes moveRight {
  0%,
  1% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(70vw);
  }
}
@keyframes moveOff {
  0%,
  1% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(110vw);
  }
}

@keyframes walkIn {
  0%,
  1% {
    transform: translateX(-5vw);
  }
  100% {
    transform: translateX(70vw);
  }
}

.mainPage {
  width: 99vw;
  height: calc(100vh - 80px);
  --homeBkgndCol: #ffffff;
  --flexWidth: 800px;
  --largeFontSize: 13vh;
  --largeLineHeight: 13vh;
  /* background-color: var(--homeBkgndCol); */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  box-sizing: border-box;
  display: flex;
}

/* --------------------------------------- */

.spacer {
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:800&display=swap');

.flexRow {
  display: flex;
  flex-direction: row;
  /* T R B L */
  margin: 10px 10px 0px 0px;
  position: relative;
}

.flexCol {
  display: flex;
  flex-direction: column;
  position: relative;
  background: transparent;
  z-index: 0;
}

.setLayer {
  width: auto;
}

.contHead {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--largeFontSize);
  line-height: var(--largeLineHeight);
  position: relative;
  width: var(--flexWidth);
}

.Head {
  margin-right: 17px;
  background-color: var(--homeBkgndCol);
  display: flex;
  position: relative;

  /* color: green; */
  font-family: 'EB Garamond', serif;
}

.contWord {
  font-size: var(--largeFontSize);
  line-height: var(--largeLineHeight);
  position: relative;
  display: inline;
  width: var(--flexWidth);
}

.Word {
  display: inline-block;
  width: auto;
  color: rgb(68, 114, 196);
  background-color: var(--homeBkgndCol);
  font-family: 'EB Garamond', serif;
}

.contBullet {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 180px;
  /* margin-left: 10px; */
  position: relative;
}

.Bullet {
  /* color: green; */
  display: flex;
  flex-direction: column;
  background-color: var(--homeBkgndCol);

  position: relative;
  font-size: 16px;
  line-height: 23px;
}

.Bullet1 {
  display: flex;
  flex-direction: column;
  background-color: var(--homeBkgndCol);
  position: relative;
  font-size: 16px;
  height: 23px;
  width: 50px;
}
.contContact {
  display: flex;
  flex-direction: column;
  width: calc(var(--flexWidth) - 345px);
  background-color: var(--homeBkgndCol);
  position: relative;
}

.Contact {
  display: flex;
  background-color: var(--homeBkgndCol);
  position: relative;
  font-size: 16px;
  line-height: 23px;
}

.Contact1 {
  display: flex;
  background-color: var(--homeBkgndCol);
  position: relative;
  font-size: 16px;
  height: 23px;
  width: 70px;
}

.playButton {
  z-index: 40;
  opacity: 1;
  position: absolute;
  bottom: 3em;
  right: 125px;
  border: 1px solid grey;
  padding: 2px;
  border-radius: 2px;
  font-family: 'Tulpen One', cursive;
  font-size: 1.2em;
  width: 50px;
  text-align: center;
}

.gotMeButton:hover,
.playButton:hover {
  color: rgb(237, 125, 49);
  border: 1px solid rgb(237, 125, 49);
}

.gotMeButton {
  z-index: 30;
  height: 23px;
  width: 50px;
  position: absolute;
  border: 1px solid grey;
  padding: 1px;
  border-radius: 2px;
  font-family: 'Tulpen One', cursive;
  font-size: 16px;
  text-align: center;
  z-index: 0;
  opacity: 0.99;
}

/* ---------------------- */
.Circles {
  --animation-time: 2s;
  --blur-target: 50px;
  --scale-target: 1;
  --hue-target: 200deg;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 100px;
  left: 200px;
  background-color: rgb(13, 150, 150);
  border-radius: 50%;
  border-color: rgb(13, 150, 150);
  border: 10px;
  z-index: 3;
  animation: flicker 1s ease alternate infinite;
}

@keyframes flicker {
  0% {
    opacity: 0.5;
    filter: blur(1px);
  }
  100% {
    opacity: 0;
    filter: hue-rotate(var(--hue-target, 270deg)) blur(var(--blur-target, 50px));
    transform: scale(var(--scale-target, 1.5));
  }
}

@media (max-width: 960px) {
  .mainPage {
    flex-direction: column;
    background-color: red;
    --flexWidth: 520px;
    --largeLineHeight: 16.5vh;
  }
}

@media (max-width: 650px) {
  .mainPage {
    flex-direction: column;
    background-color: green;
    --flexWidth: 520px;
    --largeFontSize: 10vh;
    align-items: flex-start;
    padding-left: 30px;
  }
  .flexRow {
    flex-direction: column;
  }
}

/* .modal {
  z-index: 300;
  font-family: 'Tulpen One', cursive;
  position: absolute;
  bottom: 3.5em;
  animation: walkIn 2.5s linear forwards;
} */

.content {
  font-family: 'Tulpen One', cursive;
  font-size: 1.5em;
}

.open {
  z-index: 300;
  font-family: 'Tulpen One', cursive;
  position: absolute;
  right: 12px;
  bottom: 5.5em;
  width: 240px;
  height: auto;
  border: 1px solid grey;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
}

.closed {
  z-index: 300;
  font-family: 'Tulpen One', cursive;
  position: absolute;
  right: 12px;
  bottom: 2em;
  /* animation: walkIn 2.5s linear forwards; */
  color: black;
  font-size: 1.2em;
  width: 150px;
}

.closed:hover {
  color: rgb(237, 125, 49);
}

.open:hover {
  border: 1px solid rgb(237, 125, 49);
  color: rgb(237, 125, 49);
}

@keyframes walkIn {
  0%,
  1% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(5vw);
  }
}

/* ---------------------- */

.cross {
  background: rgb(237, 125, 49);
  height: 21px;
  position: relative;
  width: 1px;
}
.cross:after {
  background: rgb(237, 125, 49);
  content: '';
  height: 1px;
  left: -10px;
  position: absolute;
  top: 10px;
  width: 21px;
}

.cross:after:hover,
.cross:hover {
  background: rgb(237, 125, 49);
}

.crossPosition {
  /* position: relative; */
  transform: translate(197px, -44px) rotate(45deg);
}
